export const hardcodedCategories = [
  "Technology",
  "Business and Finance",
  "Creative Arts",
  "Language Learning",
  "Health and Wellness",
  "Science and Engineering",
  "Social Sciences",
  "Test Preparation",
  "Professional Development",
  "Environmental Studies",
  "Education",
  "Lifestyle",
  "Personality Assessment",
];

// Define the array of school subjects
export const schoolSubjects = [
  "Maths",
  "English",
  "Hindi",
  "Science",
  "Social Studies",
  "History",
  "Geography",
  "Civics",
  "Physical Education",
  "Health Education",
  "Arts",
  "Music",
  "Dance",
  "Computer Science",
  "Environmental Science",
  // Add more subjects as needed
];

export const TechnologySubcategories = {
    "Web Development": [
      "React.js",
      "Angular",
      "Vue.js",
      "jQuery",
      "Bootstrap",
      "Sass",
      "Webpack",
      "Gatsby.js",
      "Next.js",
      "Express.js",
    ],
    "HTML, CSS, JavaScript": [
      "HTML5",
      "CSS3",
      "JavaScript ES6+",
      "Responsive Web Design",
      "CSS Grid",
      "CSS Flexbox",
      "Bootstrap Framework",
      "Tailwind CSS",
      "Styled Components",
      "jQuery Library",
    ],
    "Front-end Frameworks (React, Angular, Vue.js)": [
      "React.js",
      "Angular",
      "Vue.js",
      "Redux",
      "RxJS",
      "Ngrx",
      "Vuex",
      "Material-UI",
      "Angular Material",
      "Quasar Framework",
    ],
    "Back-end Frameworks (Node.js, Django, Flask)": [
      "Node.js",
      "Express.js",
      "Django",
      "Flask",
      "Ruby on Rails",
      "Laravel",
      "Spring Boot",
      "ASP.NET Core",
      "Symfony",
      "CakePHP",
    ],
    "Full Stack Development": [
      "MERN Stack",
      "MEAN Stack",
      "LAMP Stack",
      "Django Stack",
      "Rails Stack",
      "Spring Stack",
      "ASP.NET Stack",
      "Serverless Architecture",
      "Microservices Architecture",
      "RESTful APIs",
    ],
    "Mobile App Development": [
      "iOS Development (Swift)",
      "Android Development (Kotlin, Java)",
      "Cross-platform Development (Flutter, React Native)",
      "Xamarin",
      "Ionic Framework",
      "NativeScript",
      "PhoneGap / Apache Cordova",
      "Appcelerator Titanium",
      "jQuery Mobile",
      "Sencha Touch",
    ],
    "iOS Development (Swift)": [
      "Swift Programming Language",
      "UIKit",
      "SwiftUI",
      "Core Data",
      "ARKit",
      "Core ML",
      "Cocoa Touch",
      "Swift Package Manager",
      "TestFlight",
      "App Store Connect",
    ],
    "Android Development (Kotlin, Java)": [
      "Kotlin Programming Language",
      "Java Programming Language",
      "Android Studio",
      "Android Jetpack",
      "Firebase",
      "Room Persistence Library",
      "Retrofit",
      "Android Architecture Components",
      "Material Design",
      "Google Play Console",
    ],
    "Cross-platform Development (Flutter, React Native)": [
      "Flutter Framework",
      "Dart Programming Language",
      "React Native Framework",
      "JavaScript / TypeScript",
      "Expo",
      "Redux Thunk",
      "React Navigation",
      "Firebase Cloud Messaging",
      "CodePush",
      "App Center",
    ],
    "Data Science and Machine Learning": [
      "Python for Data Science",
      "NumPy",
      "Pandas",
      "Matplotlib",
      "Scikit-learn",
      "TensorFlow",
      "Keras",
      "PyTorch",
      "Natural Language Processing (NLP)",
      "Computer Vision",
    ],
    "Python for Data Science": [
      "Jupyter Notebook",
      "Data Analysis",
      "Data Visualization",
      "Data Cleaning",
      "Statistical Analysis",
      "Regression Analysis",
      "Classification Algorithms",
      "Clustering Algorithms",
      "Dimensionality Reduction",
      "Time Series Analysis",
    ],
    "Machine Learning": [
      "Supervised Learning",
      "Unsupervised Learning",
      "Semi-supervised Learning",
      "Reinforcement Learning",
      "Decision Trees",
      "Random Forests",
      "Support Vector Machines (SVM)",
      "K-Nearest Neighbors (KNN)",
      "Neural Networks",
      "Ensemble Learning",
    ],
    "Deep Learning": [
      "Artificial Neural Networks (ANN)",
      "Convolutional Neural Networks (CNN)",
      "Recurrent Neural Networks (RNN)",
      "Long Short-Term Memory (LSTM)",
      "Gated Recurrent Unit (GRU)",
      "Deep Belief Networks (DBN)",
      "Generative Adversarial Networks (GAN)",
      "Autoencoders",
      "Deep Reinforcement Learning",
      "Transfer Learning",
    ],
    "Data Visualization": [
      "Matplotlib",
      "Seaborn",
      "Plotly",
      "Bokeh",
      "D3.js",
      "Tableau",
      "Power BI",
      "Google Data Studio",
      "Infogram",
      "Chart.js",
    ],
    "Cybersecurity": [
      "Network Security",
      "Ethical Hacking",
      "Cybersecurity Certifications",
      "Firewalls",
      "Intrusion Detection Systems (IDS)",
      "Intrusion Prevention Systems (IPS)",
      "Antivirus Software",
      "Penetration Testing",
      "Digital Forensics",
      "Security Information and Event Management (SIEM)",
    ],
    "Network Security": [
      "Firewalls",
      "Intrusion Detection Systems (IDS)",
      "Intrusion Prevention Systems (IPS)",
      "Virtual Private Networks (VPNs)",
      "Network Access Control (NAC)",
      "Security Information and Event Management (SIEM)",
      "Packet Sniffing",
      "Denial of Service (DoS) Prevention",
      "Encryption",
      "Cyber Threat Intelligence",
    ],
    "Ethical Hacking": [
      "Footprinting",
      "Scanning",
      "Enumeration",
      "Vulnerability Assessment",
      "Penetration Testing",
      "Social Engineering",
      "Wireless Hacking",
      "Web Application Hacking",
      "System Hacking",
      "Malware Analysis",
    ],
    "Cybersecurity Certifications": [
      "Certified Ethical Hacker (CEH)",
      "CompTIA Security+",
      "CISSP (Certified Information Systems Security Professional)",
      "CISM (Certified Information Security Manager)",
      "CISA (Certified Information Systems Auditor)",
      "GIAC Security Essentials (GSEC)",
      "EC-Council Certified Security Analyst (ECSA)",
      "OSCP (Offensive Security Certified Professional)",
      "OSWP (Offensive Security Wireless Professional)",
      "CRISC (Certified in Risk and Information Systems Control)",
    ],
  };

  export const BusinessandFinanceSubcategories =  {
    Entrepreneurship: ["Startup Fundamentals", "Business Planning", "Market Research", "Venture Capital", "Pitching Ideas", "Business Model Canvas", "Entrepreneurial Mindset", "Innovation Strategies", "Lean Startup Methodology", "Growth Hacking"],
    Marketing: ["Marketing Fundamentals", "Market Segmentation", "Marketing Mix (4Ps)", "Brand Management", "Digital Marketing Strategies", "Content Marketing", "Email Marketing", "Search Engine Optimization (SEO)", "Pay-Per-Click (PPC) Advertising", "Social Media Advertising"],
    "Digital Marketing": ["Search Engine Optimization (SEO)", "Social Media Marketing", "Content Marketing", "Email Marketing", "Influencer Marketing", "Affiliate Marketing", "Digital Analytics", "Search Engine Marketing (SEM)", "Online Reputation Management", "Marketing Automation"],
    "Social Media Marketing": ["Social Media Strategy", "Social Media Platforms (Facebook, Instagram, Twitter, LinkedIn)", "Social Media Advertising", "Community Management", "Influencer Marketing", "Content Creation", "Engagement Strategies", "Analytics and Insights", "Social Media Tools", "Trends and Innovations"],
    "Finance and Accounting": ["Financial Statements Analysis", "Financial Reporting", "Managerial Accounting", "Financial Planning", "Corporate Finance", "Investment Management", "Risk Management", "Taxation", "Auditing", "Cost Accounting"],
    "Financial Management": ["Working Capital Management", "Capital Budgeting", "Financial Ratios Analysis", "Cash Flow Management", "Financial Forecasting", "Financial Risk Management", "Liquidity Management", "Debt Management", "Financial Modeling", "Dividend Policy"],
    "Accounting Principles": ["Accounting Basics", "GAAP (Generally Accepted Accounting Principles)", "Double-Entry Bookkeeping", "Trial Balance", "Income Statement", "Balance Sheet", "Cash Flow Statement", "Financial Statement Analysis", "Cost Accounting", "Managerial Accounting"],
    "Project Management": ["Project Planning", "Project Scheduling", "Scope Management", "Cost Management", "Quality Management", "Risk Management", "Communication Management", "Stakeholder Management", "Team Management", "Project Monitoring and Control"],
    "Agile and Scrum": ["Agile Principles", "Scrum Framework", "Kanban Methodology", "Lean Principles", "Agile Estimation Techniques", "User Stories", "Sprint Planning", "Daily Stand-up Meetings", "Sprint Review", "Retrospective Meetings"],
    "Project Management Certifications (PMP, CAPM)": ["Project Management Professional (PMP)", "Certified Associate in Project Management (CAPM)", "PRINCE2 (Projects IN Controlled Environments)", "PMI Agile Certified Practitioner (PMI-ACP)", "Certified ScrumMaster (CSM)", "PRINCE2 Agile", "CompTIA Project+", "Certified Associate in Project Management (IAPM)", "Certified Project Manager (IAPM)", "Certified Project Director (IAPM)"],
};

export const CreativeArtsSubcategories =  {
    "Graphic Design": ["Typography", "Logo Design", "Brand Identity Design", "Illustration", "Digital Painting", "Photo Editing", "Vector Graphics", "Print Design", "Web Design", "User Interface (UI) Design"],
    "Adobe Creative Suite": ["Adobe Photoshop", "Adobe Illustrator", "Adobe InDesign", "Adobe Premiere Pro", "Adobe After Effects", "Adobe XD", "Adobe Lightroom", "Adobe Dreamweaver", "Adobe Audition", "Adobe Spark"],
    "UI/UX Design": ["User Research", "Wireframing", "Prototyping", "Usability Testing", "Information Architecture", "User Interface Design Principles", "User Experience Design Principles", "Interaction Design", "Visual Design", "Mobile Design"],
    Photography: ["Camera Basics", "Composition Techniques", "Lighting Fundamentals", "Portrait Photography", "Landscape Photography", "Street Photography", "Product Photography", "Photo Editing", "Black and White Photography", "Fashion Photography"],
    "Music and Audio Production": ["Music Theory Fundamentals", "Digital Audio Workstations (DAWs)", "Recording Techniques", "Mixing and Mastering", "Electronic Music Production", "Sound Design", "Live Sound Engineering", "Music Composition", "Music Business", "Music Licensing"],
    "Music Theory": ["Note Reading", "Rhythm Training", "Music Scales", "Chord Progressions", "Harmony and Counterpoint", "Music Analysis", "Ear Training", "Music Composition", "Orchestration", "Jazz Theory"],
    "Sound Engineering": ["Acoustics", "Signal Flow", "Microphone Techniques", "Recording Studio Setup", "Live Sound Reinforcement", "Mixing Console Operation", "Digital Signal Processing", "Audio Effects", "Room Acoustics", "Sound System Design"],
};

export const LanguageLearningSubcategories =  {
    "English Language": ["Grammar Rules", "Vocabulary Building", "Reading Comprehension", "Writing Skills", "Speaking Fluency", "Listening Comprehension", "Idioms and Phrasal Verbs", "Pronunciation Practice", "Business English", "English Literature"],
    "Grammar and Writing": ["Parts of Speech", "Sentence Structure", "Punctuation Rules", "Writing Styles", "Paragraph Development", "Essay Writing", "Creative Writing", "Technical Writing", "Proofreading and Editing", "Grammar Exercises"],
    "Business English": ["Business Vocabulary", "Email Writing", "Business Presentations", "Meeting Skills", "Negotiation Skills", "Business Correspondence", "Report Writing", "Business Etiquette", "Cross-cultural Communication", "Business English Certifications"],
    "Foreign Languages": ["Language Basics", "Alphabet and Pronunciation", "Greetings and Introductions", "Grammar and Syntax", "Vocabulary Building", "Common Phrases and Expressions", "Travel Vocabulary", "Cultural Insights", "Language Learning Resources", "Language Exchange"],
    Spanish: ["Spanish Grammar", "Spanish Vocabulary", "Spanish Pronunciation", "Spanish Conversations", "Spanish Verb Conjugation", "Spanish Tenses", "Spanish Culture", "Spanish Literature", "Spanish for Travel", "DELE Exam Preparation"],
    French: ["French Grammar", "French Vocabulary", "French Pronunciation", "French Conversations", "French Verb Conjugation", "French Tenses", "French Culture", "French Literature", "French for Travel", "DELF/DALF Exam Preparation"],
    "Mandarin Chinese": ["Chinese Characters", "Pinyin (Chinese Phonetic Alphabet)", "Chinese Grammar", "Chinese Vocabulary", "Chinese Pronunciation", "Chinese Conversations", "Chinese Listening Comprehension", "Chinese Reading Comprehension", "Chinese Culture", "HSK Exam Preparation"],
    Japanese: ["Japanese Hiragana and Katakana", "Japanese Kanji", "Japanese Grammar", "Japanese Vocabulary", "Japanese Speaking Skills", "Japanese Writing Skills", "Japanese Listening Comprehension", "Japanese Reading Comprehension", "Japanese Pronunciation", "Japanese Culture"],
};

export const HealthandWellnessSubcategories =  {
    "Fitness and Exercise": ["Strength Training", "Cardiovascular Exercise", "Flexibility Training", "Balance and Coordination", "Endurance Training", "Sports-specific Training", "Group Fitness", "Home Workouts", "Yoga", "Pilates"],
    "Nutrition and Diet": ["Macronutrients (Carbohydrates, Proteins, Fats)", "Micronutrients (Vitamins, Minerals)", "Dietary Guidelines", "Meal Planning", "Healthy Eating Habits", "Weight Management", "Nutrition for Sports Performance", "Eating Disorders", "Food Allergies and Intolerances", "Dietary Supplements"],
    "Mental Health and Well-being": ["Stress Management", "Anxiety Reduction Techniques", "Mindfulness Meditation", "Cognitive Behavioral Therapy (CBT)", "Positive Psychology", "Emotional Intelligence", "Self-care Practices", "Resilience Building", "Mental Health First Aid", "Therapeutic Approaches"],
    "Yoga and Meditation": ["Hatha Yoga", "Vinyasa Yoga", "Ashtanga Yoga", "Yin Yoga", "Kundalini Yoga", "Meditation Techniques", "Mindfulness Practices", "Breathing Exercises (Pranayama)", "Yoga Philosophy", "Chakra Meditation"],
};
export const ScienceandEngineeringSubcategories =  {
    Physics: ["Classical Mechanics", "Thermodynamics", "Electromagnetism", "Optics", "Quantum Mechanics", "Relativity", "Astrophysics", "Fluid Dynamics", "Nuclear Physics", "Particle Physics"],
    Chemistry: ["Atomic Structure", "Chemical Bonding", "States of Matter", "Chemical Reactions", "Acids and Bases", "Thermodynamics", "Electrochemistry", "Organic Chemistry", "Inorganic Chemistry", "Analytical Chemistry"],
    Biology: ["Cell Biology", "Genetics", "Evolution", "Ecology", "Physiology", "Anatomy", "Botany", "Zoology", "Microbiology", "Immunology"],
    "Mechanical Engineering": ["Statics", "Dynamics", "Mechanics of Materials", "Fluid Mechanics", "Thermodynamics", "Heat Transfer", "Materials Science", "Mechanical Design", "Machine Design", "Engineering Mechanics"],
    "Civil Engineering": ["Structural Engineering", "Geotechnical Engineering", "Transportation Engineering", "Environmental Engineering", "Construction Engineering", "Water Resources Engineering", "Urban Planning", "Surveying", "Hydraulics", "Hydrology"],
};

export const SocialSciencesSubcategories =  {
    Psychology: ["General Psychology", "Developmental Psychology", "Social Psychology", "Cognitive Psychology", "Abnormal Psychology", "Personality Psychology", "Psychological Disorders", "Therapeutic Approaches", "Positive Psychology", "Psychological Research Methods"],
    Sociology: ["Social Institutions", "Social Stratification", "Social Change", "Cultural Sociology", "Gender Studies", "Race and Ethnicity", "Social Movements", "Urban Sociology", "Environmental Sociology", "Sociological Research Methods"],
    Anthropology: ["Cultural Anthropology", "Biological Anthropology", "Linguistic Anthropology", "Archaeology", "Anthropological Theory", "Ethnography", "Human Evolution", "Cultural Diversity", "Indigenous Peoples Studies", "Applied Anthropology"],
    "Political Science": ["Political Theory", "Comparative Politics", "International Relations", "Public Policy", "Political Economy", "Political Sociology", "Political Philosophy", "Political Methodology", "Governance and Public Administration", "Political Behavior"],
};

export const TestPreparationSubcategories =  {
    "BITS": ["BITS"], 
    "GRE/GMAT Preparation": ["GRE Quantitative Reasoning", "GRE Verbal Reasoning", "GRE Analytical Writing", "GMAT Quantitative Section", "GMAT Verbal Section", "GMAT Integrated Reasoning", "GMAT Analytical Writing Assessment", "GRE/GMAT Test-taking Strategies", "GRE/GMAT Practice Tests", "GRE/GMAT Study Plans"],
    "Joint Entrance Exam (JEE)": ["JEE Mains", "JEE Advance"],
    "Language Proficiency Tests": ["TOEFL (Test of English as a Foreign Language)", "IELTS (International English Language Testing System)", "TOEIC (Test of English for International Communication)", "DELE (Diplomas of Spanish as a Foreign Language)", "DALF (Diplôme Approfondi de Langue Française)", "HSK (Hanyu Shuiping Kaoshi - Chinese Proficiency Test)", "JLPT (Japanese Language Proficiency Test)", "CELPIP (Canadian English Language Proficiency Index Program)", "TEF (Test d'évaluation de français)", "TCF (Test de connaissance du français)"],
    "National Eligibility cum Entrance Test (NEET)": ["NEET"],
    "Olympiad (SOF)": ["Olympiad(SOF) Class 1", "Olympiad(SOF) Class 2", "Olympiad(SOF) Class 3", "Olympiad(SOF) Class 4", "Olympiad(SOF) Class 5", "Olympiad(SOF) Class 6", "Olympiad(SOF) Class 7", "Olympiad(SOF) Class 8", "Olympiad(SOF) Class 9", "Olympiad(SOF) Class 10", "Olympiad(SOF) Class 11", "Olympiad(SOF) Class 12"],
    "SAT/ACT Preparation": ["SAT Math", "SAT Reading", "SAT Writing and Language", "ACT Math", "ACT Reading", "ACT English", "ACT Science", "SAT Essay", "ACT Essay", "Test-taking Strategies"],
};

export const ProfessionalDevelopmentSubcategories =  {
    "Communication Skills": ["Effective Communication", "Business Writing", "Presentation Skills", "Public Speaking", "Interpersonal Communication", "Cross-cultural Communication", "Negotiation Skills", "Conflict Resolution", "Active Listening", "Nonverbal Communication"],
    "Leadership and Management": ["Leadership Styles", "Team Building", "Motivation Strategies", "Strategic Planning", "Change Management", "Performance Management", "Coaching and Mentoring", "Decision Making", "Emotional Intelligence", "Ethical Leadership"],
    "Time Management": ["Goal Setting", "Prioritization Techniques", "Task Management", "Time Tracking", "Procrastination Management", "Effective Scheduling", "Time Blocking", "Productivity Tools", "Managing Distractions", "Work-Life Balance"],
    "Career Development": ["Career Planning", "Resume Writing", "Job Search Strategies", "Interview Skills", "Networking Strategies", "Personal Branding", "Professional Etiquette", "LinkedIn Optimization", "Salary Negotiation", "Career Transitions"],
};

export const LifestyleSubcategories =  {
    "Cooking and Culinary Arts": ["Cooking Basics", "Baking Techniques", "International Cuisine", "Gourmet Cooking", "Vegetarian and Vegan Cooking", "Healthy Cooking", "Meal Planning", "Food Presentation", "Knife Skills", "Kitchen Hygiene"],
    "Travel and Adventure": ["Travel Planning", "Budget Traveling", "Adventure Sports", "Solo Traveling", "Family Traveling", "Cultural Immersion", "Responsible Traveling", "Eco-Tourism", "Travel Photography", "Travel Writing"],
    "Personal Finance": ["Budgeting", "Saving and Investing", "Retirement Planning", "Debt Management", "Tax Planning", "Financial Goals Setting", "Credit Management", "Insurance Basics", "Estate Planning", "Financial Independence"],
    "DIY and Home Improvement": ["Basic Home Repairs", "Painting and Decorating", "Furniture Restoration", "Plumbing Basics", "Electrical Wiring", "Gardening and Landscaping", "Home Security Systems", "Green Home Improvements", "DIY Crafts", "Home Organization"],
};

export const PersonalityAssessmentSubcategories = {
  "Social Interaction": [
      "Social Orientation",
      "Social Adaptability",
      "Networking Skills",
      "Public Speaking Confidence",
      "Group Dynamics",
      "Conflict Resolution",
      "Empathy in Conversations",
      "Relationship Building",
      "Cultural Sensitivity"
  ],
  "Decision Making": [
      "Logical Thinking",
      "Emotional Thinking",
      "Risk Assessment",
      "Problem Solving",
      "Decision Confidence",
      "Prioritization Skills",
      "Data-Driven Decisions",
      "Intuitive Decisions",
      "Decisiveness",
      "Open-Mindedness"
  ],
  "Lifestyle Preferences": [
      "Structured Routine",
      "Openness to Experience",
      "Work-Life Balance",
      "Flexibility in Plans",
      "Minimalism",
      "Adventure Seeking",
      "Creativity in Daily Life",
      "Time Management",
      "Goal Setting",
      "Preference for Stability"
  ],
  "Work Style": [
      "Team Collaboration",
      "Independent Work",
      "Work Efficiency",
      "Leadership Skills",
      "Adaptability in Work",
      "Multitasking Ability",
      "Focus and Concentration",
      "Workplace Communication",
      "Stress Handling",
      "Work Environment Preferences"
  ],
  "Emotional Intelligence": [
      "Empathy",
      "Self-Awareness",
      "Emotional Regulation",
      "Stress Management",
      "Interpersonal Skills",
      "Resilience",
      "Conflict Management",
      "Active Listening",
      "Emotional Stability",
      "Compassion"
  ],
  "Behavioral Tendencies": [
      "Risk-Taking",
      "Comfort with Uncertainty",
      "Discipline",
      "Patience",
      "Procrastination Tendency",
      "Energy Management",
      "Impulsiveness",
      "Assertiveness",
      "Curiosity",
      "Adaptability"
  ],
  "Personality Tests": [
      "Hogan Personality Inventory",
      "Hogan Development Survey",
      "Motives, Values, Preferences Inventory (MVPI)",
      "Big Five Personality Traits",
      "MBTI (Myers-Briggs Type Indicator)",
      "DISC Assessment",
      "HEXACO Personality Test",
      "16PF (16 Personality Factors)",
      "Enneagram Personality Test",
      "StrengthsFinder"
  ]
};


export const hardcodedSubcategories = {
    Technology: TechnologySubcategories,
  "Business and Finance": BusinessandFinanceSubcategories,
  "Creative Arts": CreativeArtsSubcategories,
  "Language Learning": LanguageLearningSubcategories,
  "Health and Wellness": HealthandWellnessSubcategories,
  "Science and Engineering": ScienceandEngineeringSubcategories,
  "Social Sciences": SocialSciencesSubcategories,
  "Test Preparation": TestPreparationSubcategories,
  "Professional Development": ProfessionalDevelopmentSubcategories,
  Education: {
    "Class 1": [...schoolSubjects],
    "Class 2": [...schoolSubjects],
    "Class 3": [...schoolSubjects],
    "Class 4": [...schoolSubjects],
    "Class 5": [...schoolSubjects],
    "Class 6": [...schoolSubjects],
    "Class 7": [...schoolSubjects],
    "Class 8": [...schoolSubjects],
    "Class 9": [...schoolSubjects],
    "Class 10": [...schoolSubjects],
    "Class 11": [...schoolSubjects],
    "Class 12": [...schoolSubjects],
  },
  Lifestyle: LifestyleSubcategories,
  "Personality Assessment": PersonalityAssessmentSubcategories,
};
